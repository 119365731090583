<template>
  <app-card-wizard title="Firma İletişim Bilgileri">
    <section class="form-section" style="margin-bottom:30px;margin-top:50px;">
      <template v-if="isHq">
        <h2 class="mb-1">Kanuni Merkez</h2>
        <p>
          Firma iletişim bilgilerinizi eksiksiz bir şekilde doldurun.
        </p>
      </template>
      <template v-if="!isHq">
        <h2 class="mb-1">{{ city.name }} Şubesi</h2>
        <p>
          Şube iletişim bilgilerinizi eksiksiz bir şekilde doldurun.
        </p>
      </template>
    </section>
    <section class="form-section">
      <h6>
        <span v-if="!isHq">Şube Adresi</span>
        <span v-if="isHq">Adres</span>
      </h6>
      <div class="row">
        <div class="col-md-4">
          <app-form-group name="city" label="Şehir">
            <input
              readonly="true"
              name="city"
              class="form-control"
              placeholder="Şehir Seçin"
              :value="city.name"
            />
          </app-form-group>
        </div>
        <div class="col-md-4">
          <app-form-group :validator="validator" name="county" label="İlçe">
            <p-dropdown
              style="width:100%;"
              v-model="county"
              :options="counties"
              :showClear="true"
              :filter="true"
              filterLocale="tr"
              filterPlaceholder="Ara"
              emptyFilterMessage="Sonuç bulunamadı"
              placeholder="İlçe seçin"
              @change="updateAddress('county', $event.value)"
            >
            </p-dropdown>
          </app-form-group>
        </div>
        <div class="col-md-4">
          <app-form-group
            :validator="validator"
            name="zipCode"
            label="Posta kodu"
          >
            <input
              name="zipCode"
              class="form-control"
              placeholder="Posta kodu"
              maxlength="5"
              v-numeric
              v-model.lazy.trim="zipCode"
              @input="updateAddress('zipCode', $event.target.value)"
            />
          </app-form-group>
        </div>
      </div>
      <app-form-group :validator="validator" name="address" label="Adres">
        <input
          name="address"
          class="form-control"
          placeholder="Mahalle, Cadde, Sokak, No"
          maxlength="200"
          v-model.lazy.trim="address"
          @input="updateAddress('address', $event.target.value)"
        />
      </app-form-group>
    </section>
    <section class="form-section">
      <h6><span v-if="!isHq">Şube </span>Telefon ve Faks Numaraları</h6>
      <app-form-group :validator="validator" name="phones">
        <div class="mb-2" v-for="(item, index) in phones" :key="item">
          <app-phone-input
            v-model="phones[index]"
            :remove="index > 2"
            @remove="onRemovePhone(index)"
            @input="updatePhones"
          ></app-phone-input>
        </div>
      </app-form-group>
      <a class="add-phone" @click="onAddPhone"
        ><i class="far fa-plus"></i> Numara Ekle
      </a>
    </section>
    <section class="form-section">
      <h6>E-posta Adresi</h6>
      <app-form-group
        :validator="validator"
        name="email"
        :label="(isHq ? 'Firma ' : 'Şube ') + 'e-posta adresi'"
      >
        <input
          name="email"
          class="form-control"
          placeholder="Firmanıza ait e-posta adresi girin"
          maxlength="100"
          v-model.trim.lazy="email"
          @change="updateAddress('email', $event.target.value)"
        />
      </app-form-group>
      <app-form-group
        :validator="validator"
        name="kep"
        label="Kayıtlı E-posta Adresi (KEP)"
        v-if="isHq"
      >
        <input
          name="kep"
          class="form-control"
          placeholder="Varsa KEP adresinizi belirtin. KEP adresiniz yoksa boş bırakabilirsiniz."
          maxlength="100"
          v-model.lazy.trim="kep"
          @change="updateAddress('kep', $event.target.value)"
        />
      </app-form-group>
      <app-form-group
        :validator="validator"
        name="url"
        label="Firma Web Sitesi Adresi"
        v-if="isHq"
      >
        <input
          name="url"
          class="form-control"
          placeholder="Varsa firmanıza ait web sitesi adresi belirtin"
          maxlength="100"
          v-model.trim.lazy="webSite"
          @change="updateAddress('webSite', $event.target.value)"
        />
      </app-form-group>
    </section>
    <app-wizard-buttons
      :next-button-disabled="isLoading"
      :next-button-is-loading="isLoading"
      :next-button-text="nextButtonText"
      @next-button-click="onSubmit"
      :prev-button-visible="prevButtonVisible"
      :prev-button-disabled="isLoading"
      @prev-button-click="goToPreviousStep"
    ></app-wizard-buttons>
  </app-card-wizard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { settings, rules, getCounties } from "../shared";
import { url } from "@vuelidate/validators";
import AppPhoneInput from "../components/PhoneInput.vue";

const defaultPhones = () => [
  {
    type: "phone",
    phone: null
  },
  {
    type: "gsm",
    phone: null
  },
  {
    type: "fax",
    phone: null
  }
];

export default {
  components: {
    AppPhoneInput
  },
  data() {
    return {
      isLoading: false,
      addressType: "hq",
      county: null,
      zipCode: null,
      address: null,
      email: null,
      kep: null,
      webSite: null,
      phones: defaultPhones()
    };
  },
  validations: {
    county: rules.required,
    zipCode: rules.zipCode,
    address: rules.required,
    email: rules.email,
    kep: rules.kep,
    url: { url },
    phones: rules.phones
  },
  methods: {
    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.scrollToError();
        return;
      }

      this.isLoading = true;

      setTimeout(() => {
        const address = {
          city: this.city.name,
          county: this.county,
          zipCode: this.zipCode || "",
          address: this.address,
          email: this.email,
          kep: this.kep || "",
          webSite: this.url,
          phones: this.phones
        };

        this.updateApplicationData({
          [this.isHq ? "hqAddress" : "branchOfficeAddress"]: address
        });

        this.isLoading = false;

        let nextStep =
          this.isHq &&
          this.applicationData.selectedAssociation?.isAppliedFromBranch === true
            ? "/company-address-branch/"
            : "/company-contacts/";

        if (this.applicationData.fromReview) nextStep = "/review/";

        if (nextStep === "/company-address-branch/") {
          this.county = null;
          this.zipCode = null;
          this.address = null;
          this.email = null;
          this.kep = null;
          this.webSite = null;
          this.phones = defaultPhones();

          this.$v.$reset();
        }

        this.markStepAsCompleted(this.stepName);

        this.goTo(nextStep + this.applicationData.stateId);

        this.isLoading = false;
      }, 500);
    },
    goToPreviousStep() {
      this.redirect(
        (this.isHq ? "/company-info/" : "/company-address-hq/") +
          this.applicationData.stateId
      );
    },
    updatePhones() {
      this.updateAddress("phones", this.phones);
    },
    onAddPhone() {
      this.phones.push({
        type: "phone",
        phone: null
      });
    },
    onRemovePhone(id) {
      const phones = [...this.phones];
      phones.splice(id, 1);

      this.phones = [];

      this.phones = phones;
    },
    setAddressType() {
      this.addressType = this.$route.params.addressType;
      if (this.addressType != "branch" && this.addressType != "hq")
        this.addressType = "hq";
    },
    updateAddress(name, value) {
      const propName = this.isHq ? "hqAddress" : "branchOfficeAddress";

      const address = this.applicationData[propName] || {};
      address[name] = value;

      this.updateApplicationData({ [propName]: address });
    },
    loadState() {
      this.setAddressType();
      const propName = this.isHq ? "hqAddress" : "branchOfficeAddress";

      const address = (this.applicationData || {})[propName] || {};

      this.county = address.county;
      this.zipCode = address.zipCode;
      this.address = address.address;
      this.email = address.email;
      this.kep = address.kep;
      this.webSite = address.webSite;
      this.phones = address.phones || defaultPhones();
    },
    ...mapActions(["updateApplicationData"])
  },
  computed: {
    prevButtonVisible() {
      return !this.applicationData.fromReview;
    },
    nextButtonText() {
      return this.applicationData.fromReview ? "Kaydet" : "Devam Et";
    },
    stepName() {
      return this.isHq ? "company-address-hq" : "company-address-branch";
    },
    validator() {
      return this.$v || {};
    },
    url() {
      const _url = this.webSite?.toLowerCase();

      if (!_url) return _url;

      return _url.startsWith("http://") || _url.startsWith("https://")
        ? _url
        : "http://" + _url;
    },
    counties() {
      return getCounties(this.city?.id).map((x) => x.name);
    },
    isHq() {
      return this.addressType === "hq";
    },
    city() {
      const code = this.isHq
        ? this.applicationData.hqCity
        : this.applicationData.selectedAssociation?.appliedFromCity;

      return settings.getCity(code);
    },
    hqAddress() {
      return this.applicationData.hqAddress;
    },
    ...mapGetters({
      applicationData: "getApplicationData"
    })
  },
  beforeMount() {
    this.loadState();

    this.markStepAsInCompleted(this.stepName);
  },
  watch: {
    $route() {
      this.loadState();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/_variables";

.add-phone {
  text-decoration: none;
  cursor: pointer;
  color: #007bff;
  font-weight: $font-weight-semi-bold;
}
</style>
