<template>
  <div
    ref="inputGroup"
    :class="[
      'app-phone input-group',
      {
        'app-phone-focus': focus,
        'app-phone-filled': filled,
        'has-remove': remove
      }
    ]"
  >
    <div class="input-group-prepend">
      <p-dropdown
        v-model="value.type"
        :options="phoneTypes"
        dataKey="value"
        optionValue="value"
        optionLabel="label"
        @change="onInput"
        @show="onFocus"
        @hide="onDropdownHide"
      >
      </p-dropdown>
    </div>
    <p-input-mask
      :class="['form-control', { 'form-control-filled': filled }]"
      v-model.trim.lazy="value.phone"
      placeholder="Numara"
      mask="(999) 999 99 99"
      @change="onInput"
      @focus="onFocus"
      @blur="onBlur"
    ></p-input-mask>
    <div :class="['input-group-append']" v-if="remove">
      <button
        type="button"
        class="btn btn-close"
        aria-label="Sil"
        @click="onRemove"
        @focus="onFocus"
        @blur="onBlur"
      >
        <i class="far fa-times"></i>
      </button>
    </div>
  </div>
</template>

<script>
import PDropdown from "primevue/dropdown";
import PInputMask from "primevue/inputmask";

export default {
  components: {
    PDropdown,
    PInputMask
  },
  emits: ["update:modelValue", "focus", "blur", "remove"],
  props: {
    modelValue: {
      type: Object,
      default() {
        return {
          type: "phone",
          phone: null
        };
      }
    },
    id: Number,
    remove: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: {
        type: "phone",
        phone: null
      },
      focus: false,
      phoneTypes: [
        {
          label: "Sabit Telefon",
          value: "phone"
        },
        {
          label: "Cep Telefonu",
          value: "gsm"
        },
        {
          label: "Faks",
          value: "fax"
        }
      ]
    };
  },
  methods: {
    onRemove() {
      this.$emit("remove");
    },
    onInput() {
      this.$emit("update:modelValue", this.value);
      this.$emit("input", this.value);
    },
    onFocus(event) {
      this.focus = true;
      this.$emit("focus", event);
    },
    onBlur(event) {
      this.focus = false;
      this.$emit("blur", event);
    },
    onDropdownHide(event) {
      this.onBlur(event);

      try {
        this.$refs.inputGroup.children[1].focus();
      } catch (error) {
        //ignored
        console.error(error);
      }
    }
  },
  computed: {
    filled() {
      return (
        this.modelValue?.phone != null &&
        this.modelValue?.phone.toString().length > 0
      );
    }
  },
  created() {
    this.value = this.modelValue || {
      type: "phone",
      phone: null
    };

    if (!this.phoneTypes.some((x) => x.value == this.value.type))
      this.value.type = "phone";
  }
};
</script>
<style scoped lang="scss">
@import "../scss/variables";
@import "../scss/mixins";

.app-phone {
  &:hover {
    .btn-close {
      color: #999;
    }
  }

  &.app-phone-focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

    .form-control,
    .btn-close,
    .p-dropdown {
      border-color: #80bdff !important;
      outline: 0;
      box-shadow: none;
    }

    .btn-close {
      color: #999;
    }

    .input-group-prepend:after {
      border-color: #80bdff !important;
    }
  }

  .input-group-prepend {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      z-index: 5;
      content: "";
      border-right: solid 2px $input-border-color;
      top: 10px;
      bottom: 10px;
      right: -6px;
    }
  }

  .p-dropdown {
    width: 160px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none;

    &.p-focus {
      border-color: $input-border-color;
      outline: 0;
      box-shadow: none;
    }
  }

  .btn-close {
    border: solid 2px $input-border-color;
    border-left: none;
    padding-left: 7px;
    padding-right: 16px;
    color: #fff;
    @include transition();

    &:focus,
    &:active,
    &:hover {
      color: #000;
    }
  }

  .form-control {
    border-left: none;
    padding-left: 22px;
    padding-right: 0;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .btn-close,
  .form-control {
    &:focus {
      box-shadow: none;
    }
  }

  &.has-remove {
    .form-control {
      border-left: none;
      border-right: none;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}
</style>
